import React from 'react';

import { Box, Card, CardBody, Divider, Flex, HStack, VStack } from '@chakra-ui/react';
import millify from 'millify';
import { useRouter } from 'next/navigation';

import { LoadingButton } from '@components/custom-buttons';
import { CustomText } from '@components/Custom-text';
import Rating from '@components/rating/Rating';
import { Mentor } from '@modules/auth/types';
import { paths } from '@routes/path';

interface MentorCardProps {
    mentorInfo: Mentor;
}

const MentorCard = ({ mentorInfo }: MentorCardProps) => {
    const router = useRouter();
    return (
        <Card
            display="flex"
            variant="outline"
            size="sm"
            _hover={{
                borderColor: 'primary.light',
                boxShadow: '1px 3px 10px gray',
            }}
        >
            <CardBody px={{ base: '5px', sm: '5px', md: '12px' }}>
                <VStack width="100%">
                    <Box
                        height="220px"
                        minH="220px"
                        width="100%"
                        position="relative"
                        borderRadius="6px"
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0.45)), url(${mentorInfo.profileImageUrl})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <VStack
                            alignItems="self-start"
                            gap={0}
                            position="absolute"
                            bottom={0}
                            left={2}
                        >
                            <CustomText color="white" weight={500} size="md">
                                {mentorInfo?.name ?? '-'}
                            </CustomText>
                            <CustomText color="white" weight={300}>
                                {`${mentorInfo?.currentRole ?? '-'}, ${
                                    mentorInfo?.domain?.name ?? '-'
                                } `}
                            </CustomText>
                        </VStack>
                    </Box>
                    <Flex w="100%" justifyContent="space-between" mb={3}>
                        <VStack w="100%" alignItems="flex-start" gap={0}>
                            <CustomText color="mainText" size="xs">
                                Session Completed
                            </CustomText>
                            <CustomText color="primary.dark" size="sm" weight={500}>
                                {mentorInfo.sessionsCompleted ?? 0} Sessions
                            </CustomText>
                        </VStack>
                        <VStack w="100%" gap={0} alignItems="end">
                            <CustomText color="mainText" size="xs">
                                Reviews {`(${millify(mentorInfo?.totalReviews ?? 0)})`}
                            </CustomText>
                            <HStack alignItems="center" width="100%" gap={1}>
                                <Rating rating={mentorInfo.totalRating ?? 0} />
                                <CustomText color="#ECC94B" size="sm" weight={500}>
                                    {mentorInfo.totalRating ?? 0}
                                </CustomText>
                            </HStack>
                        </VStack>
                    </Flex>
                    <Divider orientation="horizontal" />
                    <LoadingButton
                        buttonLabel="Book Session"
                        onClick={() =>
                            router.push(`${paths.general.findMentor}/${mentorInfo?.username}`)
                        }
                        isLoading={false}
                        loadingText="booking"
                        type="button"
                    />
                </VStack>
            </CardBody>
        </Card>
    );
};

export default MentorCard;
