'use client';

import React, { useState } from 'react';

import { Box, Checkbox, CheckboxGroup, Input, VStack } from '@chakra-ui/react';

import { Loader } from '@components/custom-loader';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { useGetSkillsQuery } from '@modules/common/api';
import { setFindMentorFilters } from '@modules/find-mentor/slice';

const Expertise = () => {
    const [query, setQuery] = useState<string>('');
    const { data: skillsData, isFetching } = useGetSkillsQuery({
        offset: 0,
        limit: 30,
        search: query,
    });
    const dispatch = useAppDispatch();
    const { expertise } = useAppSelector(state => state.reducer.mentorFilters);
    const onChange = (value: string[]) => {
        dispatch(
            setFindMentorFilters({
                expertise: value,
            })
        );
    };
    return (
        <VStack mt="5px" gap={2} width="100%" align="flex-start">
            <Input
                value={query}
                padding={6}
                onChange={e => {
                    setQuery(e.target.value.trim());
                }}
                type="text"
                bgColor="white"
                placeholder="Search Expertise"
            />
            <Box
                bgColor="white"
                borderWidth={1}
                borderColor="border"
                borderRadius={5}
                p={5}
                maxH="45vh"
                width="100%"
                overflowY="scroll"
                className="hide-scroll"
            >
                {isFetching ? (
                    <Loader height="inherit" width="100%" size="md" />
                ) : (
                    <CheckboxGroup onChange={onChange} value={expertise} colorScheme="blue">
                        <VStack align="flex-start" gap={6}>
                            {skillsData?.skillsArray.map((skill, index) => (
                                <Checkbox key={`key${index}`} value={skill}>
                                    {skill}
                                </Checkbox>
                            ))}
                        </VStack>
                    </CheckboxGroup>
                )}
            </Box>
        </VStack>
    );
};

export default Expertise;
