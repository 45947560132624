import { baseApi } from '@store/baseQuery';

import {
    DomainResponse,
    HobbiesResponse,
    InterestsResponse,
    ServicesResponse,
    SkillFilters,
    SkillsResponse,
} from '../types';

export const domainApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getDomains: builder.query<DomainResponse, void>({
            query: () => ({
                url: '/persona/domains?offset=0&limit=100',
                method: 'GET',
            }),
            transformResponse: (response: DomainResponse, meta, arg) => {
                const domainArr = response?.domain.map(item => ({
                    value: item.id,
                    key: item.name,
                }));
                return {
                    ...response,
                    domainArray: domainArr,
                };
            },
        }),
        getInterests: builder.query<InterestsResponse, void>({
            query: () => ({
                url: '/persona/interests?offset=0&limit=100',
                method: 'GET',
            }),
            transformResponse: (response: InterestsResponse, meta, arg) => {
                const interestsArr = response?.interests.map(item => item.name);
                return {
                    ...response,
                    interestsArray: interestsArr,
                };
            },
        }),
        getHobbies: builder.query<HobbiesResponse, void>({
            query: () => ({
                url: '/persona/hobbies?offset=0&limit=100',
                method: 'GET',
            }),
            transformResponse: (response: HobbiesResponse, meta, arg) => {
                const hobbiesArr = response?.hobbies.map(item => item.name);
                return {
                    ...response,
                    hobbiesArray: hobbiesArr,
                };
            },
        }),
        getSkills: builder.query<SkillsResponse, SkillFilters>({
            query: ({ offset, limit, search, orderBy, orderDirection }) => ({
                url: '/persona/skills',
                method: 'GET',
                params: {
                    offset: offset ?? 0,
                    limit: limit ?? 10,
                    orderBy: orderBy ?? 'name',
                    search: search ?? undefined,
                    orderDirection: orderDirection ?? 'ASC',
                },
            }),
            transformResponse: (response: SkillsResponse, meta, arg) => {
                const skillsArr = response?.skills.map(item => item.name);
                return {
                    ...response,
                    skillsArray: skillsArr,
                };
            },
        }),
        getServices: builder.query<ServicesResponse, { search?: string }>({
            query: ({ search }) => ({
                url: '/persona/services?offset=0&limit=100&orderDirection=DESC&orderBy=createdAt',
                method: 'GET',
                params: {
                    search: search ?? undefined,
                },
            }),
            transformResponse: (response: ServicesResponse) => {
                const servicesArr = response?.services.map(item => ({
                    value: item.id,
                    key: item.type,
                }));
                return {
                    ...response,
                    servicesArray: servicesArr,
                };
            },
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetDomainsQuery,
    useGetHobbiesQuery,
    useGetInterestsQuery,
    useGetSkillsQuery,
    useGetServicesQuery,
} = domainApi;
